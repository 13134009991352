/* @font-face {
  font-family: "Robus";
  src: url("./assets/fonts/robus/Robus\ Demo.otf") format("opentype");
} */
@font-face {
  font-family: "Noise-machine";
  src: url("./assets/fonts/noise-machine/Noise\ Machine.ttf")
    format("truetype");
}
@font-face {
  font-family: "Manly";
  src: url("./assets/fonts/manly_man_font/Manly%20Man%20Font.ttf")
    format("truetype");
}
@font-face {
  font-family: "Grandis-extended";
  src: url("./assets/fonts/font/GrandisExtended-Regular.woff2") format("woff2"),
    url("./assets/fonts/font/GrandisExtended-Regular.woff") format("woff");
}
@font-face {
  font-family: "Grandis-medium";
  src: url("./assets/fonts/font/GrandisExtended-Medium.woff2") format("woff2"),
    url("./assets/fonts/font/GrandisExtended-Medium.woff") format("woff");
}
