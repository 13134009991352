
.faq-cont{
    display: grid;
    place-items: center;
    /* height: 850px; */
    /* padding: 100px 0; */
    background-image: url("../../assets/images/faq-bg.png") !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 95%;
    height: 1024px;
    margin: 0 auto;
    position: relative;
}
.faq{
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    border-radius: 20px;
    border: 1px solid #E31617;
    border-bottom: 0;
    width: 70%;
    margin: 0 auto;
}
.faq-body{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
    margin: 0 auto;
    z-index: 1;
}
.each-faq{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.each-faq h3{
    color: var(--Blue-grey-blue-grey-500, #C7CFD6);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.each-faq h3 img{
    cursor: pointer;
}
.each-faq p{
    color: var(--Blue-grey-blue-grey-900, #9aa2ab);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: none;
}
.faq-gradient{
    border-radius: 12620.545px;
    background: var(--Gradient-Horiz-Red-to-Yellow, linear-gradient(270deg, #FB3 0%, #E31617 100%));
    filter: blur(252.41090393066406px);
    z-index: 1;
    width: 641.124px;
    height: 202px;
    position: absolute;
    top: 40%;
    left: 25%;
}
@-moz-document url-prefix() {
    .faq-gradient {
      opacity: 0.3;
    }
  }


@media (max-width: 1110px) {
    .faq-body{
        width: 90%;
    }
}
@media (max-width: 950px) {
    .faq{
        width: 85%;
    }
    .faq-gradient{
        width: 500px;
        left: 20px;
    }
}
@media (max-width: 550px) {
    .faq{
        width: 90%;
    }
    .faq-cont{
        height: auto;
        padding: 80px 0 40px;
    }
    .each-faq h3{
        font-size: 16px;
    }
    .each-faq p{
        font-size: 16px;
    }
    .faq-body{
        width: 100%;
    }
    .faq-gradient{
        width: 90%;
        left: 20px;
        /* display: none; */
    }
}