* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  max-width: 2000px;
  margin: 0 auto;
  background: black;
  color: white;
}

h1 {
  font-family: Robus;
}
p,
input,
button,
textarea,
ul,
li {
  font-family: Grandis-extended;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: Grandis-medium;
}
input,
button,
textarea {
  outline: none;
  background: transparent;
  border: none;
}
a{
  text-decoration: none;
}

.btn {
  color: #fff;
  text-align: center;
  -webkit-text-stroke-width: 0.20000000298023224;
  -webkit-text-stroke-color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-image: url(./assets/images/btn-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 231px;
  height: 48px;
  cursor: pointer;
  z-index: 2;
}
.btn-sec {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-image: url(./assets/images/btn-trans.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 197px;
  height: 48px;
  cursor: pointer;
  color: #e31617;
  z-index: 2;
  /* background-color: var(
    --Gradient-Diagonal-Red-to-yellow,
    linear-gradient(150deg, #e31617 7.67%, #fb3 92.33%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 0.20000000298023224;
  -webkit-text-stroke-color: #000; */
}

.btn-hero {
  width: 286px !important;
  height: 56px !important;
  font-size: 20px !important;
  z-index: 2;
}

@media (max-width: 550px) {
  .btn-hero {
    width: 194.071px !important;
    height: 38px !important;
    font-size: 14px !important;
  }
  
}
