.svg-comp {
  background-image: url("../../assets/images/line-grid.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 95%;
  height: 600px;
  margin: 0 auto;
  position: relative;
}

.input-cont {
  border: 1px solid var(--Blue-grey-blue-grey-900, #54575a);
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: var(--Neutrals-White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-cont input{
    width: 100%;
    color: var(--Neutrals-White, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input-cont textarea{
    color: var(--Blue-grey-blue-grey-900, #54575A);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 149px;
}

.img-comp{
    background-image: url("../../assets/images/background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 180px 20px;
}

@media (max-width: 550px) {
  .svg-comp {
    background-image: url("../../assets/images/mobile-svg.png");
    width: 95%;
    height: 650px;
  }
  .img-comp{
    padding: 60px 0;
  }
}
