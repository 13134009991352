
.about-comp{ 
    background-image: url("../../assets/images/about-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 95%;
    height: auto;
    /* padding-bottom: 40px; */
    margin: 0 auto 60px;
    position: relative;
    padding: 200px 0;
}
.about-cont{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 80%;
    margin: 0 auto;
    padding: 24px 32px;
    border-radius: 20px;
    border: 1px solid #E31617;
    border-bottom: 0;
}
.about-comp h4, .mission h4{
    color: var(--Blue-grey-blue-grey-500, #C7CFD6);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.about-comp p, .mission p{
    color: var(--Blue-grey-blue-grey-900, #9aa2ab);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
}
.mission-vision{
    display: flex;
    align-items: center;
    gap: 70px;
    width: 80%;
    margin: 100px auto 0;
}
.mission{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 24px 32px;
    border-radius: 20px;
    border: 1px solid #E31617;
    border-bottom: 0;

}

@media (max-width: 1240px) {
    .about-cont, .mission-vision{
        width: 95%;
    }
    .about-comp{
        height: auto;
        padding-bottom: 40px;
    }
}
@media (max-width: 1110px) {
    .about-cont, .mission-vision{
        width: 98%;
    }
}
@media (max-width: 950px) {
    .mission-vision{
        flex-direction: column;
    }
    .about-comp{ 
        background-image: url("../../assets/images/mobile-about.png");
        height: auto;
        padding-bottom: 40px;
        width: 100%;
        /* border: 2px solid green; */
    }
    .about-cont, .mission-vision{
        width: 80%;
    }
}
@media (max-width: 550px) {
    .mission-vision{
        flex-direction: column;
    }
    .about-cont, .mission-vision{
        width: 90%;  
    }
    .about-cont{
        padding: 24px 10px;
    }
    .about-comp p, .mission p{
        font-size: 14px;
    }
    .about-comp{
        padding-top: 120px;
        /* height: 2600px; */
        height: auto;
        padding-bottom: 40px;
    }
    .mission{
        padding: 24px;
    }
    .about-cont{
        padding: 24px;
    }
}