
.hero{
    background-image: url('../../assets/images/background.png');
    background-size: cover;  
    background-position: center; 
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 15px 80px;
    position: relative;
}
.hero-head{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.hero-head h1{
    text-align: center;
    font-family: Robus;
    font-size: 128px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px;
    background: linear-gradient(180deg, #585858 0%, #FFF 45.52%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.hero-head p{
    color: rgba(255, 255, 255, 0.40);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.hero-img{
    display: flex;
    gap: 54px;
    align-items: center;
    justify-content: center;
}
.hero-gradient{
    border-radius: 12620.545px;
    background: var(--Gradient-Horiz-Red-to-Yellow, linear-gradient(270deg, #FB3 0%, #E31617 100%));
    filter: blur(252.41090393066406px);
    z-index: 1;
    width: 641.124px;
    height: 202px;
    position: absolute;
    bottom: -100px;
    left: 25%;
}
.hero-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(20px);
    z-index: -1;
}
@-moz-document url-prefix() {
    .hero-gradient {
      opacity: 0.3;
    }
}

@media (max-width: 950px) {
    .hero-head h1{
        font-size: 112px;
    }
    .hero-head p{
        font-size: 18px;
    }
    .hero-img img{
        width: 53.859px;
        height: 53.859px;
    }
    .hero-gradient{
        width: 500px;
        left: 20px;
    }
}

@media (max-width: 550px) {
    .hero-head h1{
        font-size: 60px;
        line-height: 48px;
        /* width: 90%;
        margin: 0 auto; */
    }
    .hero-head p{
        font-size: 14px;
        /* width: 90%;
        margin: 0 auto; */
    }
    .hero-img img{
        width: 37.096px;
        height: 37.096px;
    }
    .hero-img{
        gap: 20px;
    }
    .hero-gradient{
        width: 90%;
        left: 20px;
        bottom: 0;
    }
}