.contact-cont {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding: 120px 0 80px;
  position: relative;
  width: 85%;
  margin: 0 auto;
  gap: 15px;
  /* border: 1px solid red; */
}
.contact-left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 50%;
}
.contact-right {
  width: 50%;
}
.c-left_head {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.c-left_head h2 {
  background: linear-gradient(180deg, #585858 0%, #fff 45.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.c-left_head p {
  width: 80%;
}
.c-left_head p,
.c-left_socials p {
  color: var(--Blue-grey-blue-grey-900, #9aa2ab);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.c-left_socials {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}
.c-left_socials h4 {
  color: var(--Blue-grey-blue-grey-600, #b5bcc3);
  leading-trim: both;
  text-edge: cap;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border: 1px solid green; */
}
.c-l_social-links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.c-left_email {
  display: flex;
  gap: 10px;
  align-items: center;
}
.c-left_email img{
  width: 20px;
  height: 20px;
}
.c-l_socials_icons {
  display: flex;
  align-items: center;
  gap: 26px;
  z-index: 2;
}
.c-l_socials_icons img {
  cursor: pointer;
}
.contact-gradient {
  border-radius: 12620.545px;
  background: var(
    --Gradient-Horiz-Red-to-Yellow,
    linear-gradient(270deg, #fb3 0%, #e31617 100%)
  );
  filter: blur(252.41090393066406px);
  width: 641.124px;
  z-index: 1;
  height: 202px;
  position: absolute;
  bottom: -100px;
  left: 40%;
  /* border: 2px solid red !important; */
}
.contact-right {
  /* border: 2px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 50px;
}
.contact-btn {
  width: 100%;
}
.input-width {
  width: 90% !important;
  margin: 0 auto !important;
}
@-moz-document url-prefix() {
  .contact-gradient {
    opacity: 0.3;
  }
}

@media (max-width: 1110px) {
  .contact-gradient {
    width: 90%;
    left: 20px;
  }
}

@media (max-width: 950px) {
  .contact-gradient {
    width: 90%;
    left: 20px;
  }
  .contact-cont {
    flex-direction: column;
  }
  .c-left_head {
    text-align: center;
  }
  .c-left_head h2 {
    font-size: 42px;
  }
  .c-left_socials {
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    padding-top: 40px;
    /* border: 2px solid red; */
    align-items: center;
  }
  .c-l_social-links,
  .c-left_email {
    align-items: center;
  }
  .contact-right {
    width: 100%;
    padding-top: 100px;
  }

  .contact-left {
    width: 100%;
  }
  .c-left_head p {
    margin: 0 auto;
  }
  .c-left_socials h4 {
    font-size: 20px;
  }
}

@media (max-width: 550px) {
  .contact-gradient {
    width: 90%;
    left: 20px;
    /* display: none; */
  }
  .c-left_socials h4 {
    font-size: 14px;
  }
  .contact-cont {
    flex-direction: column;
    width: 95%;
  }
  .c-left_head h2 {
    font-size: 28px;
  }
  .contact-left p {
    font-size: 16px;
  }
  .c-left_head {
    text-align: center;
  }
  .c-left_head p {
    width: 95%;
  }
  .c-left_socials {
    align-items: center;
    flex-direction: column;
  }
  .c-l_social-links,
  .c-left_email {
    align-items: center;
  }
  .input-width {
    width: 100% !important;
  }
}
