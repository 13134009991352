
.nav{
    /* border: 1px solid red; */
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
    position: relative;
    z-index: 2;
}
.nav-links{
    display: flex;
    align-items: center;
    gap: 44px;
}
.nav-links ul, .tab-menu_cont ul{
    list-style: none;
}
.nav-links ul li, .tab-menu_cont ul li{
    color: rgba(255, 255, 255, 0.40);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.active-link{
    background-image: linear-gradient(150deg, #E31617 7.67%, #FB3 92.33%) !important;
    -webkit-background-clip: text !important; 
    background-clip: text !important;
    color: transparent !important;
}
.tab-menu{
    display: none;
}
.tab-menu_cont{
    position: absolute;
    border-radius: 20px;
    border: 1px solid var(--Gradient-Diagonal-Red-to-yellow, #E31617);
    right: 0;
    bottom: -170%;
    background: var(--Neutrals-Black, #000);
    box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.50);
    display: none;
    width: 242px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    z-index: 2;
}
.tab-menu_cont ul li:hover{
    color: #FFF;
}

@media (max-width: 1110px) {
    .nav{
        width: 95%;
        padding: 30px 0;
    }
}
@media (max-width: 950px) {
    .nav-links{
        display: none;
    }
    .nav-btn{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .tab-menu{
        display: block;
        cursor: pointer;
    }
    .tab-menu_cont{
        display: flex;
    }
}

@media (max-width: 550px) {
    .nav-btn .btn{
        display: none;
    }
    .nav{
        width: 85%;
    }
    .nav-logo img{
        width: 99px;
        height: 24.048px;
    }
    .tab-menu img{
        width: 24px;
        height: 24px;
    }
    .mobile-menu_cont{
        position: relative;
    }
    .mobile-menu_cont{
        align-items: center;
    }
    .mobile-menu_cont{
        width: 90%;
        margin: 0 auto;
    }
}